import React from 'react';
import Layout from '../layouts/layout';
import WelcomeLogo from '../components/welcome-logo.js'
import StoreInfo from '../components/store-info.js'
import ReviewSection from '../components/review-section.js';

export default () => (
  <Layout>
    <WelcomeLogo />
    <StoreInfo />
    <ReviewSection />
  </Layout>
)
