import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import styles from '../css-modules/Body.module.css';

export default function ReviewCard(props) {
  const stars = [];
  for (let i = 0; i < Math.ceil(props.rating); i++) {
    stars.push(<FontAwesomeIcon icon={faStar} key={i} />)
  }

  return (
    <div className={styles.reviewWrapper}>
      <div>
        <div className={styles.reviewHeader}>
          <div>
            <img src={props.photoUrl} alt={props.name}/> <br/>
          </div>

          <div>
            <div className={styles.starWrapper}>
              {stars}
            </div>
            <strong>{props.name}</strong> <br/>
            {props.date.slice(0, props.date.indexOf(' '))}
          </div>
        </div>
      </div>
      <div>
        <p>{props.reviewText} <br/><a href={props.url}>Read more</a></p>
      </div>
    </div>
  );
}
