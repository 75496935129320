import React from 'react';
import SawasdeeGreetLogo from '../assets/sawasdee-icon.svg';
import styles from '../css-modules/Body.module.css';

export default () => (
  <div className={styles.svgLogoContainer}>
    <SawasdeeGreetLogo />
    <div style={{width: '100%', textAlign: 'center'}}>
      <h1>Welcome!</h1>
    </div>
  </div>
)
