import React from 'react';
import ReviewCard from './review-card';
import styles from '../css-modules/Body.module.css';

class ReviewSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviews: []
    }
  }

  componentDidMount() {
    const uri = 'https://api-yelp.herokuapp.com';
    const endpoint = '/reviews/sawasdee-soquel';


    if (process.env.NODE_ENV === 'production') {
      fetch(uri + endpoint, {mode: 'cors'})
        .then(res => res.json())
        .then(data => this.setState({ reviews: data.reviews }))
    }
    else if (process.env.NODE_ENV === 'development'){
      fetch('http://localhost:5000/reviews/sawasdee-soquel')
      .then(res => res.json())
      .then(data => this.setState({ reviews: data.reviews }))
    }
  }

  render() {
    return (
      <div className={styles.aboutSection}>
        <div className={styles.reviewSection}>
          {
            this.state.reviews && this.state.reviews.map(review => <ReviewCard
              key={review.url}
              rating={review.rating}
              reviewText={review.text}
              date={review.time_created}
              name={review.user.name}
              photoUrl={review.user.image_url}
              url={review.url}/>)
          }
        </div>
      </div>
    )
  }
}


export default ReviewSection;
